export const GET_USER_STATS = gql`
  query stats {
    OrderStats {
      id
      status
      offers {
        status
      }
    }
  }
`;
